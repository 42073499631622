<template>
  <b-card no-body class="mb-2">
    <b-card-header class="align-items-center">
      <h4 class="mr-auto mb-0">
        <unicon name="bookmark" width="18" height="18" class="mr-1"></unicon>
        تفاصيل الخدمة
      </h4>
    </b-card-header>
    <ValidationObserver ref="serviceDto">
      <b-form>
        <b-card-body>
          <b-card-text>
            <b-row>
              <b-col cols="12" md="6">
                <EKInputText
                  :rules="[
                    {
                      type: 'required',
                      message: 'اسم الخدمة مطلوب',
                    },
                  ]"
                  label="اسم الخدمة"
                  placeholder="ادخل اسم الخدمة"
                  name="name"
                  v-model="serviceDto.name"
                />
                <EKInputTextarea
                  label="نص الخدمة"
                  v-model="serviceDto.description"
                  :rules="[
                    {
                      type: 'required',
                      message: 'يرجى ملىء نص الخدمة',
                    },
                  ]"
                  placeholder="ادخل نص الخدمة"
                  name="description"
                  rows="8"
                />
              </b-col>
              <b-col cols="12" md="6">
                <EKInputImage
                  label="صورة الخدمة"
                  required
                  title="أرفق الملف هنا أو اضغط لتحميل"
                  :value="
                    serviceDto.documents[0]
                      ? serviceDto.documents[0].path
                        ? serviceDto.documents[0].path.replace(
                            /^/,
                            $store.getters['app/domainHost'] + '/'
                          )
                        : ''
                      : ''
                  "
                  @input="addNewImage($event)"
                ></EKInputImage>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
        <b-card-footer class="py-1 d-flex">
          <b-button class="mr-1" @click="submitUpdateService()" variant="primary">
            تعديل
          </b-button>
          <b-button variant="outline-primary" to="./">تراجع</b-button>
          <b-button
            variant="outline-danger"
            class="ml-auto"
            @click="deleteService(serviceDto.id)"
            v-if="id != nullGuid"
            >حذف</b-button
          >
        </b-card-footer>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import { mapActions, mapState } from "vuex";
import { nullGuid } from "@/EK-core/util/global";
import { handleDashLangChange } from "@/libs/event-bus";
export default {
  components: {
    ValidationObserver,
    EKInputImage,
    EKInputText,
    EKInputTextarea,
  },
  props: {
    id: String,
  },
  data: () => ({
    nullGuid,
  }),
  computed: {
    ...mapState({
      serviceDto: (state) => state.service.serviceDto,
    }),
  },
  created() {
    this.getServiceDetails(this.id);
    handleDashLangChange(() => {
      this.getServiceDetails(this.id);
    });
  },
  methods: {
    ...mapActions(["getServiceDetails", "updateService", "deleteService"]),
    submitUpdateService() {
      this.$refs.serviceDto.validate().then((success) => {
        if (
          success &&
          (this.serviceDto.documents[0].file || this.serviceDto.documents[0].path)
        ) {
          this.updateService(this.serviceDto);
        }
      });
    },
    addNewImage(img) {
      if (this.serviceDto.documentIds[0].id != nullGuid)
        this.serviceDto.documentIds.splice(0, 1);
      this.serviceDto.documents[0] = {
        id: nullGuid,
        file: img,
        path: "",
      };
      console.log(this.serviceDto.documentIds);
    },
  },
  beforeDestroy() {
    this.$refs.serviceDto.reset();
  },
};
</script>
